import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import SEO from "../components/ui/seo"
import { contact } from "../components/data/static-data"

import EmailIcon from "@material-ui/icons/Email"
import PhoneIcon from "@material-ui/icons/Phone"


import Layout from "../components/ui/layout"
import { Paper } from "@material-ui/core"

import Sabrina from "../images/sabrina.jpg"
import CallBg from "../images/callbg.jpg"


const useStyles = makeStyles(theme => ({
    contacGlobalContainer: {

        //width: "100%"
    },
    infoContainer: {
        backgroundColor: theme.palette.common.white,
        padding: "2rem 2rem",
        marginTop: "1rem",
        backgroundImage: `url(${CallBg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // marginBottom: "2rem"
    },
    row: {
        width: "70%"
    },
    contactContainer: {
        marginTop: "7rem",
        width: "100%",
        // height: "120rem"
    },
    iconContainer: {
        marginRight: "1rem",
        //width: "50%"
    },
    mapPaper: {
        height: "20rem",
        width: "100%"
    },
    bureauPaper: {
        padding: "3.5rem 0 3.5rem 2rem",
        width: "45rem",

        backgroundColor: "#e5e3df"
    },
    bureauContainer: {
        height: "100%",
        borderRadius: 0,
        width: "20rem"
    },
    imgContainer: {
        width: "21rem"
    },
    imgSabrina: {
        width: "20rem",
        height: "10rem"
    },
    contactItem: {
        width: "100%",
        margin: "1rem 1rem"
    },
    contactButton: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.light
        }
    },
    infoCard: {
        padding: "2rem 1rem"
    },
    entronsTypo: {
        borderLeft: `5px solid ${theme.palette.secondary.main}`,
        padding: "0.5rem 0.5rem"
    },
    googleMaps: {
        border: 0,
        width: "100%",
        height: "20rem"
    }
}))

// const handleMailClick = () =>{
//     window.location = 'mailto:contact.immodefi@gmail.com'
// }

const contacts = [
    { icon: <PhoneIcon style={{ fill: "#fff" }} />, titre: "APPELEZ-NOUS", info: `${contact.phone}`, link: `tel:${contact.phone}` },
    { icon: <EmailIcon style={{ fill: "#fff" }} />, titre: "ECRIVEZ-NOUS", info: `${contact.email}`, link: `mailto:${contact.email}` }
    // { icon: <GroupIcon style={{ fill: "#fff" }} />, titre: "PRENEZ RENDEZ-NOUS", info: "Cliauez ici", link: "/" }
]


const Contact = () => {
    const classes = useStyles()
    const theme = useTheme()

    const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("xs"))
    const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("sm"))

    return (
        <Layout>
            <SEO title="Contact" description="Confiez nous votre bien, nous nous occupons de tout" />
            <Grid item container alignItems="center" classes={{ root: classes.contactContainer }} direction="column">
                <Card style={{width: "61.5%", padding: "1rem 4rem", marginBottom: "1rem"}} elevation={2}>
                    <Grid item container direction="column">
                        <Grid item>
                            <Typography classes={{ root: classes.entronsTypo }} style={{ color: theme.palette.primary.main, }} variant="h1">Entrons en contact</Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: "#000", marginBottom: "2rem" }} variant="body1">Nous sommes à votre disposition pour échanger sur votre projet d'investissement immobilier</Typography>
                        </Grid>
                    </Grid>
                </Card>
                <Grid item container classes={{ root: classes.row }} justifyContent="center" direction="row">
                    <Grid item container justifyContent="center" classes={{ root: classes.imgContainer }} direction="column">
                        <Grid item>
                            <img className={{ root: classes.imgSabrina }} style={{ width: "25rem" }} src={Sabrina} alt="Sabrina" />
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: theme.palette.common.black, width: "25rem" }} variant="body1">
                                Échangez avec Sabrina, elle s'occupe du reste !
                            </Typography>
                        </Grid>
                    </Grid>
                    <Paper classes={{ root: classes.bureauPaper }}>
                        <Grid item container classes={{ root: classes.bureauContainer }} justifyContent="center" direction="column">
                            <Grid item>
                                <Typography classes={{ root: classes.entronsTypo }} style={{ color: theme.palette.primary.main }} variant="h1">
                                    Notre Cabinet Nantes centre
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    ImmoDéfi  6, rue Porte Neuve 44000, Nantes
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid classes={{ root: classes.row }} item>
                    <Grid
                        item
                        container
                        classes={{ root: classes.infoContainer }}
                        alignContent="center"
                        alignItems="center"
                        justifyContent="space-around"
                        direction="row" >
                        {
                            contacts.map(contact => (
                                <Grid item container style={{ width: "auto" }} direction="column" key={contact.titre}>
                                    <Grid item container classes={{ root: classes.contactItem }} alignItems="center" alignContent="center" justifyContent="center">
                                        <Button component="a" to={contact.link} classes={{ root: classes.contactButton }}>
                                            <Grid item classes={{ root: classes.iconContainer }}>
                                                {contact.icon}
                                            </Grid>
                                            <Grid item container direction="column">
                                                <Grid item>
                                                    <Typography style={{ color: "#fff" }} variant="h1">{contact.titre}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography style={{ color: theme.palette.common.white, textTransform: 'none', textDecoration: 'none' }}
                                                        component={contact.link ? "a" : undefined}
                                                        to={contact.link ? contact.link : undefined}
                                                        href={contact.link ? contact.link : undefined}
                                                        variant="body1">{contact.info}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Button>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid classes={{ root: classes.row }} item style={{ marginTop: "2rem" }}>
                    <Paper elevation={2} classes={{ root: classes.mapPaper }}>
                        <Typography classes={{ root: classes.entronsTypo }} style={{ color: theme.palette.primary.main }} variant="h1">Ou passez nous voir</Typography>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2709.8579582774832!2d-1.5633600844679596!3d47.219361522640945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4805ec1e16edc173%3A0x753bc3b2fb3c1826!2s6%20Rue%20Prte%20Neuve%2C%2044000%20Nantes%2C%20France!5e0!3m2!1sfr!2sbf!4v1647377282037!5m2!1sfr!2sbf" className={classes.googleMaps} allowfullscreen="true" loading="lazy">
                        </iframe>
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}
export default Contact